import React from 'react';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFingerprint,
    faBookOpen,
    faWindowClose,
    faSave,
    faCheck,
    faPlay,
    faPencilAlt,
    faFileDownload,
    faTrashAlt,
    faCopyright,
    faHashtag,
    faUser,
    faLock
} from '@fortawesome/free-solid-svg-icons';

import { H5PEditorUI, H5PPlayerUI } from '@lumieducation/h5p-react';

import { IContentListEntry, IContentService } from '../services/ContentService';
import './ContentListEntryComponent.css';

export default class ContentListEntryComponent extends React.Component<{
    contentService: IContentService;
    data: IContentListEntry;
    onDelete: (content: IContentListEntry) => void;
    onDiscard: (content: IContentListEntry) => void;
    onSaved: (data: IContentListEntry) => void;
    generateDownloadLink: (contentId: string) => string;
}> {
    constructor(props: {
        contentService: IContentService;
        data: IContentListEntry;
        onDiscard: (content: IContentListEntry) => void;
        onDelete: (content: IContentListEntry) => void;
        onSaved: (data: IContentListEntry) => void;
        generateDownloadLink: (contentId: string) => string;
    }) {
        super(props);
        this.state = {
            editing: false,
            playing: true,
            saving: false,
            saved: false,
            loading: true,
            saveErrorMessage: '',
            saveError: false,
            showingCustomCopyright: false,
            showContextIdModal: false,
            showAsUserIdModal: false,
            readOnlyState: false,
            allowDownload: true
        };
        this.h5pEditor = React.createRef();
        this.saveButton = React.createRef();
        this.h5pPlayer = React.createRef();
        this.contextIdInput = React.createRef();
        this.asUserIdSelect = React.createRef();

    }
    public async componentDidMount(): Promise<void> {
        const queryParams = new URLSearchParams(window.location.search);
        const allowDownload = queryParams.get('allowDownload') === 'true';
        console.log('allowDownload', allowDownload);
        this.setState({ allowDownload });
    }
    public async componentWillUnmount(): Promise<void> {
    }
    public state: {
        asUserId?: string;
        contextId?: string;
        editing: boolean;
        loading: boolean;
        playing: boolean;
        readOnlyState: boolean;
        saved: boolean;
        saveError: boolean;
        saveErrorMessage: string;
        saving: boolean;
        showAsUserIdModal: boolean;
        showContextIdModal: boolean;
        showingCustomCopyright: boolean;
        allowDownload: boolean;
    };

    private h5pPlayer: React.RefObject<H5PPlayerUI>;
    private h5pEditor: React.RefObject<H5PEditorUI>;
    private saveButton: React.RefObject<HTMLButtonElement>;
    private contextIdInput: React.RefObject<HTMLInputElement>;
    private asUserIdSelect: React.RefObject<HTMLSelectElement>;

    public render(): React.ReactNode {
        // @ts-ignore
        return (
            <ListGroupItem
                key={
                    this.props.data.originalNewKey ?? this.props.data.contentId
                }
            >
                <Container>
                    <Row>
                        <React.Fragment>
                            <Col className="p-12" lg="12">
                                <a className="float-end mb-2"
                                    href={this.props.generateDownloadLink(
                                        this.props.data.contentId
                                    )}
                                >
                                    {this.props.data.role !== 12 && this.state.allowDownload ? (
                                        <Button variant="success">
                                            <FontAwesomeIcon
                                                icon={faFileDownload}
                                                className="me-2"
                                            />
                                            Tải về
                                        </Button>
                                    ) : undefined }

                                </a>
                            </Col>
                            {/*<Col className="p-2" lg="auto">
                                <Button
                                    variant="success"
                                    onClick={() => this.play()}
                                >
                                    <FontAwesomeIcon
                                        icon={faPlay}
                                        className="me-2"
                                    />
                                    Nộp bài
                                </Button>
                            </Col>*/}
                            {/*<Col className="p-2" lg="auto">
                                <a
                                    href={this.props.generateDownloadLink(
                                        this.props.data.contentId
                                    )}
                                >
                                    <Button variant="success">
                                        <FontAwesomeIcon
                                            icon={faFileDownload}
                                            className="me-2"
                                        />
                                        Tải về
                                    </Button>
                                </a>
                            </Col>
                            <Col className="p-2" lg="auto">
                                <Button
                                    variant="danger"
                                    onClick={() =>
                                        this.props.onDelete(this.props.data)
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        className="me-2"
                                    />
                                    Xóa
                                </Button>
                            </Col>*/}
                        </React.Fragment>
                    </Row>
                </Container>
                {this.state.playing ? (
                    <div className={this.state.loading ? 'loading' : ''}>
                        {/*<strong>asUserId : {this.state.asUserId}</strong>*/}
                        <H5PPlayerUI
                            ref={this.h5pPlayer}
                            contentId={this.props.data.contentId}
                            contextId={this.state.contextId || undefined}
                            asUserId={this.state.asUserId || undefined}
                            readOnlyState={this.state.readOnlyState}
                            loadContentCallback={
                                this.props.contentService.getPlay
                            }
                            onInitialized={this.onPlayerInitialized}
                            onxAPIStatement={(
                                statement: any,
                                context: any,
                                event
                            ) =>
                            {
                                //console.log('lap lai');
                                console.log(statement);
                                const parentUrl =  process.env.REACT_APP_PARENT_URL ?? '';
                                const payload = { key: 'xAPI', statement };
                                window.parent.postMessage({type: 'callParentFunction', payload}, parentUrl);
                            }}
                        />
                        <div
                            style={{
                                visibility: this.state.loading
                                    ? 'visible'
                                    : 'collapse'
                            }}
                            className="spinner-border spinner-border-sm m-2"
                            role="status"
                        ></div>
                    </div>
                ) : undefined}
            </ListGroupItem>
        );
    }

    protected async play() {
        /*let i = this.h5pPlayer.current?.render();
        console.log(i);*/
        //this.setState({ editing: false, playing: true });
    }

    protected edit() {
        this.setState({ editing: true, playing: false });
    }

    protected close() {
        this.setState({ editing: false, playing: false });
    }

    protected showCopyrightCustom() {
        this.setState({ showingCustomCopyright: true });
    }
    private onPlayerInitialized = () => {
        this.setState({ loading: false });
    };
    protected showContextIdModal() {
        this.setState({ showContextIdModal: true });
        setTimeout(() => {
            this.contextIdInput.current?.focus();
        }, 100);
    }

    protected closeContextIdModal() {
        this.setState({ showContextIdModal: false });
    }

    protected showAsUserIdModal() {
        this.setState({ showAsUserIdModal: true });
        setTimeout(() => {
            this.asUserIdSelect.current?.focus();
        }, 100);
    }

    protected async save() {

    }

    protected onSaveError = async (event) => {
        console.log('onSaveError', event);
        this.setState({
            saving: false,
            saved: false,
            saveError: true,
            saveErrorMessage: event.detail.message
        });
        setTimeout(() => {
            this.setState({
                saveError: false
            });
        }, 5000);
    };

    protected onSaved = async (event) => {
        this.setState({
            saving: false,
            saved: true
        });
        setTimeout(() => {
            this.setState({ saved: false });
        }, 3000);
    };

    protected onEditorLoaded = () => {
        this.setState({ loading: false });
    };

    protected setContextId = () => {
        this.setState({
            contextId: this.contextIdInput.current?.value,
            showContextIdModal: false
        });
    };

    protected setAsUserId = () => {
        this.setState({
            asUserId: this.asUserIdSelect.current?.value,
            showAsUserIdModal: false
        });
    };

    private isNew() {
        return this.props.data.contentId === 'new';
    }
}
